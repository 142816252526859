import { fetcher } from "./utility";
import { store } from "../";
import { eventGA, setIdGA } from "./TrackerGA";
import { setUser } from "../actions/user";
import { emitMessage } from "../actions/socket";
import { setHome, homeSetSlots, homeSetNotifications } from "../actions/home";
import { setConversationPreview } from "../actions/conversation";

let initialized = false;
let queue = [];

export function fb(callback) {
  if (initialized) {
    callback(window.FB);
  } else {
    queue.push(callback);
    if (!window.fbAsyncInit) {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: "309826149501197",
          autoLogAppEvents: false,
          status: true,
          cookie: true,
          xfbml: false,
          version: "v17.0",
        });
        initialized = true;
        queue.forEach((cb) => cb(window.FB));
        queue = null;
      };
      /*const script =
        window.localStorage.getItem("fb:debug") === "true"
          ? "xfbml.customerchat/debug.js"
          : "xfbml.customerchat";*/
      // const script = "xfbml.customerchat";
      var tag = document.createElement("script");
      tag.async = true;
      tag.src = "https://connect.facebook.net/it_IT/sdk.js";
      var body = document.querySelector("body");
      body.appendChild(tag);
    }
  }
}

export function facebookRedirect(redirect_uri) {
  // Google's OAuth 2.0 endpoint for requesting an access token
  var oauth2Endpoint = "https://www.facebook.com/v8.0/dialog/oauth";

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  var form = document.createElement("form");
  form.setAttribute("method", "GET"); // Send as a GET request.
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  var params = {
    client_id: "309826149501197",
    redirect_uri: window.location.origin + redirect_uri,
    response_type: "token",
    scope: "public_profile,email,user_birthday,user_gender",
  };

  // Add form parameters as hidden input values.
  for (var p in params) {
    var input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

export const isFacebookApp = () => {
  //return true;
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  return (
    ua.indexOf("FBAN") > -1 ||
    ua.indexOf("FBAV") > -1 ||
    ua.indexOf("Instagram") > -1
  );
};
const fbLogInThipibo = (access_token) => {
  if (!window.FACEBOOK_LOGGED) {
    window.FACEBOOK_LOGGED = true;
    fetcher({
      path: "/api/login_facebook",
      credentials: true,
      redirect: "follow",
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      body: { access_token },
    })
      .then((res) => {
        if (!res.success) {
          console.log("login fallito");
          window.FACEBOOK_LOGGED = false;
        } else {
          if (res.newUser) {
            eventGA(
              "ACQUISIZIONI",
              "Registrazione_facebook",
              "User_" + res.user._id,
              res.user.city
            );
            res.user.new = true;
            res.user.pwaInstallSeen = true;
          }
          res.user.logged = true;
          setIdGA(res.user._id);
          //console.log("successo loggedin. Utente ID", res.user);
          res.user.tenants.forEach((tenant, index) => {
            if (tenant.user_id) {
              let user_tenant = res.user_tenants.find(
                (x) => x._id == tenant.user_id
              );
              if (user_tenant) {
                //console.log("USER TENANT SET", user_tenant);
                let pickedUser = {};
                pickedUser.value = user_tenant._id;
                pickedUser.image = user_tenant.profile_image;
                pickedUser.image_webp = user_tenant.profile_image_webp;
                pickedUser.location = user_tenant.location;
                pickedUser.age = user_tenant.age;
                pickedUser.type = user_tenant.type;
                pickedUser.label = user_tenant.name + " " + user_tenant.surname;
                res.user.tenants[index].pickedUser = pickedUser;
                res.user.tenants[index].image = user_tenant.profile_image;
                res.user.tenants[index].image_webp =
                  user_tenant.profile_image_webp;
              }
            }
          });
          store.dispatch(setUser(res.user));
          if (res.notifications != null) {
            //console.log("not in login  ", res.notifications);
            store.dispatch(homeSetNotifications(res.notifications));
          }
          if (res.home != null) {
            res.home.tenants.forEach((tenant, i_t) => {
              if (tenant.user_id) {
                let home_tenant = res.home_tenants.find(
                  (x) => x._id == tenant.user_id
                );
                if (home_tenant) {
                  let pickedUser = {};
                  pickedUser.value = home_tenant._id;
                  pickedUser.image = home_tenant.profile_image;
                  pickedUser.image_webp = home_tenant.profile_image_webp;
                  pickedUser.location = home_tenant.location;
                  pickedUser.age = home_tenant.age;
                  pickedUser.type = home_tenant.type;
                  pickedUser.label =
                    home_tenant.name + " " + home_tenant.surname;
                  res.home.tenants[i_t].pickedUser = pickedUser;
                  res.home.tenants[i_t].image = home_tenant.profile_image;
                  res.home.tenants[i_t].image_webp =
                    home_tenant.profile_image_webp;
                }
              }
            });
            if (res.ads != null) {
              res.home.advertises = res.ads;
            }
            store.dispatch(setHome(res.home));

            if (res.slotArray != null) {
              store.dispatch(homeSetSlots(res.slotArray));
            }
          }
          if (res.notifications != null) {
            store.dispatch(homeSetNotifications(res.notifications));
            //console.log("not log", res.notifications);
          }
          if (res.conversations != null) {
            store.dispatch(setConversationPreview(res.conversations));
            //console.log("conv log", res.conversations);
          }
          if (res.home != null) {
            store.dispatch(emitMessage("login_home", res.home._id));
            store.dispatch(emitMessage("login_user", res.user._id));
          } else store.dispatch(emitMessage("login_user", res.user._id));
          window.FACEBOOK_LOGGED = false;
        }
      })
      .catch((e) => {
        console.log("Error during Fetch:", e);
        window.FACEBOOK_LOGGED = false;
      });
  }
};

export const fbLogIn = (token) => {
  //console.log("FBLOGIN")
  if (token) fbLogInThipibo(token);
  else
    fb((FB) => {
      FB.getLoginStatus(function (response) {
        //console.log("RISPOSTA_SALVATA", response);
        let access_token;
        if (response.authResponse) {
          access_token = response.authResponse.accessToken;
        }
        fbLogInThipibo(access_token);
      });
    });
};

async function instagramPopup() {
  let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=400,height=500,left=50%,top=50%`;

  const win = window.open(
    "https://www.instagram.com/oauth/authorize?client_id=948957055613500&redirect_uri=" +
      window.location.origin +
      "/popup-redirect&scope=user_profile&response_type=code",
    "Instagram Access",
    params
  );
  if (win) win.opener = window;
  return new Promise((resolve, reject) => {
    listenToMessageEvent(win, resolve, reject);
  });
}

function instagramRedirect(redirect_uri) {
  // Google's OAuth 2.0 endpoint for requesting an access token
  var oauth2Endpoint = "https://www.instagram.com/oauth/authorize";

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  var form = document.createElement("form");
  form.setAttribute("method", "GET"); // Send as a GET request.
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  var params = {
    client_id: "948957055613500",
    redirect_uri: window.location.origin + redirect_uri,
    response_type: "code",
    scope: "user_profile",
  };

  // Add form parameters as hidden input values.
  for (var p in params) {
    var input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

const listenToMessageEvent = (win, resolve, reject) => {
  const instagramCheckInterval = setInterval(() => {
    var instagramCode = localStorage.getItem("instagramCode");
    if (instagramCode != null) {
      localStorage.removeItem("instagramCode");
      if (win) win.close();
      //console.log("UE POLLING", instagramCode);
      clearInterval(instagramCheckInterval);
      window.removeEventListener("message", windowEventHandler);
      resolve(instagramCode);
    }
  }, 300);
  const windowEventHandler = (event) => {
    let hash = event.data;
    // eslint-disable-next-line no-console
    if (hash.type === "oauth_token") {
      clearInterval(instagramCheckInterval);
      const token = hash.access_token;
      window.removeEventListener("message", windowEventHandler);
      if (win) win.close();
      resolve(token);
    } else if (hash.type == "error") {
      clearInterval(instagramCheckInterval);
      console.error(hash.message);
      window.removeEventListener("message", windowEventHandler);
      if (win) win.close();
      reject(hash.message);
    }
  };
  window.addEventListener("message", windowEventHandler, false);
};

export function instagramThipiboFetch(token, redirect_uri, callback) {
  fetcher({
    path: "/api/instagramAccess",
    credentials: true,
    method: "POST",
    body: {
      token,
      redirect_uri: window.location.origin + redirect_uri,
    },
  })
    .then((res) => {
      if (!res.success) console.log(res.error);
      else {
        callback(res.username);
      }
    })
    .catch((e) => console.log("Error during Fetch:", e));
}

export async function instagramAccess(mobile, callback, mobile_redirect_uri) {
  try {
    if (mobile) {
      instagramRedirect(mobile_redirect_uri);
    } else {
      const token = await instagramPopup();
      if (!token) {
        throw "No message received";
      }
      instagramThipiboFetch(token, "/popup-redirect", callback);
    }
  } catch (e) {
    return { error: { message: e } };
  }
}

export async function instagramCheck(username) {
  try {
    const res = await fetcher({
      path: `/api/check-instagram/${username}`,
      method: "GET",
      credentials: true,
    });
    console.log("RES", res);
    return res;
  } catch (e) {
    console.log("Error during Fetch:", e);
  }
}

export function tiktokAccess(redirect_uri) {
  fetcher({
    path: "/api/tiktokAccess",
    credentials: true,
    method: "POST",
    body: {
      redirect_uri: window.location.origin + redirect_uri,
      // redirect_uri: "https://thipibo.com", // + redirect_uri,
    },
  }).then((res) => {
    window.location.href = res.url;
  });
}

export function tiktokGetData(token, redirect_uri, callback) {
  fetcher({
    path: "/api/getTiktokData",
    credentials: true,
    method: "POST",
    body: {
      code: token,
      redirect_uri: window.location.origin + redirect_uri,
    },
  })
    .then((res) => {
      if (!res.success) console.log(res.error);
      else {
        callback(res.username);
      }
    })
    .catch((e) => console.log("Error during Fetch:", e));
}

const messengerRequest = (FB, response, callback) => {
  FB.api("/me", "GET", { fields: "link,name" }, function (meRes) {
    //console.log("ME!", response, meRes);
    callback({ accessToken: response.authResponse.accessToken, ...meRes });
  });
};

export function messengerAccess(callback) {
  fb((FB) => {
    FB.login(
      function (response) {
        //console.log("response!", response);
        messengerRequest(FB, response, callback);
      },
      { scope: "public_profile,email,user_birthday,user_gender,user_link" }
    );
  });
}
